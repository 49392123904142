/* HEXAGON STARTS HERE */
.hex {
	width:150px;
	height:86px;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-webkit-background-size: auto 173px;
	-moz-background-size: auto 173px;
	-ms-background-size: auto 173px;
	-o-background-size: auto 173px;
	position: relative;
	float:left;
	margin:25px 5px;
	text-align:center;
	zoom:1;
}

	.hex.hex-gap {
		margin-left: 86px;
	}

	.hex a {
		display:block;
		width: 100%;
		height:100%;
		text-indent:-9999em;
		position:absolute;
		top:0;
		left:0;
	}

	.hex .corner-1,
	.hex .corner-2 {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background: inherit;
		z-index:0;
		overflow:hidden;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.hex .corner-1 {
		z-index:0;
		-webkit-transform: rotate(60deg);
		-moz-transform: rotate(60deg);
		-ms-transform: rotate(60deg);
		-o-transform: rotate(60deg);
		transform: rotate(60deg);
	}

	.hex .corner-2 {
		-webkit-transform: rotate(-60deg);
		-moz-transform: rotate(-60deg);
		-ms-transform: rotate(-60deg);
		-o-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}

	.hex .corner-1:before,
	.hex .corner-2:before {
		width: 173px;
		height:	173px;
	  content: '';
	  position: absolute;
	  background: inherit;
	  top:0;
	  left: 0;
	  z-index: 0;
	  background: inherit;
	  background-repeat:no-repeat;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		backface-visibility: hidden;
	}


	.hex .corner-1:before {
		-webkit-transform: rotate(-60deg) translate(-87px, 0px);
		-moz-transform: rotate(-60deg) translate(-87px, 0px);
		-ms-transform: rotate(-60deg) translate(-87px, 0px);
		-o-transform: rotate(-60deg) translate(-87px, 0px);
		transform: rotate(-60deg) translate(-87px, 0px);
	  -webkit-transform-origin: 0 0;
	  -moz-transform-origin: 0 0;
	  -ms-transform-origin: 0 0;
	  -o-transform-origin: 0 0;
	  transform-origin: 0 0;
	}

	.hex .corner-2:before {
		-webkit-transform: rotate(60deg) translate(-48px, -11px);
		-moz-transform: rotate(60deg) translate(-48px, -11px);
		-ms-transform: rotate(60deg) translate(-48px, -11px);
		-o-transform: rotate(60deg) translate(-48px, -11px);
		transform: rotate(60deg) translate(-48px, -11px);
		bottom:0;
	}



	/* Custom styles*/
	.hex .inner {
		color:#eee;
		position: relative;
		z-index: 3;
	}

	.hex h4 {
		margin:0;
	}

	.hex hr {
		border:0;
		border-top:1px solid #eee;
		width:60%;
		margin:15px auto;
	}

	.hex p {
		font-size:16px;
		width:80%;
		margin:0 auto;
	}

	.hex.hex-1 {
		background: #74cddb;
	}

	.hex.hex-2 {
		background: #f5c53c;
	}

	.hex.hex-3 {
		background: #80b971;
	}
